import * as React from 'react';
import ArticleSection from '../components/ArticleSection/ArticleSection';
import DefaultPageHeader from '../components/DefaultPageHeader/DefaultPageHeader';
import PageTemplate from '../components/PageTemplate/PageTemplate';
import TricolorMorphDef from '../components/TricolorMorphDef/TricolorMorphDef';
import { PageComponent } from '../types/gatsby.types';
import './data-protection-policy.scss';

const PageDataProtectionPolicy: PageComponent = (props) => {
  return (
    <PageTemplate
      {...props} // always include
      className="PageDataProtectionPolicy"
      title="Data Protection Policy"
      description="AxonDivision's Data Protection Policy."
    >
      <TricolorMorphDef />
      <DefaultPageHeader
        id="DataProtectionPolicyPageHeader"
        title="Data Protection Policy"
        titleFontSize="md"
      ></DefaultPageHeader>
      <ArticleSection>
        <p>
          <strong>
            This privacy policy has been compiled to better serve those who are
            concerned with how their ‘Personally Identifiable Information’ (PII)
            is being used online. PII, is information that can be used on its
            own or with other information to identify, contact, or locate a
            single person, or to identify an individual in context. Please read
            our privacy policy carefully to get a clear understanding of how we
            collect, use, protect or otherwise handle your Personally
            Identifiable Information in accordance with our website.
          </strong>
        </p>

        <h3>
          What personal information do we collect from the people that visit our
          website?
        </h3>
        <p>
          You may be asked to enter your name, email address or other details to
          help you with your experience.
        </p>

        <h3>When do we collect information?</h3>
        <p>
          We collect information from you when you fill out a form or enter
          information on our site.
        </p>

        <h3>How do we use your information?</h3>
        <p>
          We may use the information we collect from you when you sign up for
          our newsletter, respond to a survey or marketing communication, surf
          the website, or use certain other site features in the following ways:
        </p>
        <ul>
          <li>
            To allow us to better service you in responding to your customer
            service requests.
          </li>
          <li>
            To send periodic emails regarding your order or other products and
            services.
          </li>
          <li>
            To follow up with them after correspondence (live chat, email or
            phone inquiries)
          </li>
        </ul>

        <h3>How do we protect your information?</h3>
        <ul>
          <li>
            We do not use vulnerability scanning and/or scanning to PCI
            standards.
          </li>
          <li>We only provide articles and information. </li>
          <li>We never ask for credit card numbers. </li>
          <li>We use regular Malware Scanning.</li>
        </ul>

        <p>
          Your personal information is contained behind secured networks and is
          only accessible by a limited number of persons who have special access
          rights to such systems, and are required to keep the information
          confidential. In addition, all sensitive/credit information you supply
          is encrypted via Secure Socket Layer (SSL) technology.We implement a
          variety of security measures when a user enters, submits, or accesses
          their information to maintain the safety of your personal
          information.All transactions are processed through a gateway provider
          and are not stored or processed on our site.
        </p>

        <h3>Do we use ‘cookies’?</h3>
        <p>
          Yes. Cookies are small files that a site or its service provider
          transfers to your computer’s hard drive through your Web browser (if
          you allow) that enables the site’s or service provider’s systems to
          recognize your browser and capture and remember certain information.
          For instance, we use cookies to help us remember and process the items
          in your shopping cart. They are also used to help us understand your
          preferences based on previous or current site activity, which enables
          us to provide you with improved services. We also use cookies to help
          us compile aggregate data about site traffic and site interaction so
          that we can offer better site experiences and tools in the future.
        </p>

        <h3>We use cookies to:</h3>
        <ul>
          <li>
            Understand and save user’s preferences for future visits.&nbsp;
          </li>
          <li>
            Compile aggregate data about site traffic and site interactions in
            order to offer better site experiences and tools in the future.
          </li>
          <li>
            We may also use trusted third-party services that track this
            information on our behalf.
          </li>
        </ul>
        <p>
          You can choose to have your computer warn you each time a cookie is
          being sent, or you can choose to turn off all cookies. You do this
          through your browser settings. Since browser is a little different,
          look at your browser’s Help Menu to learn the correct way to modify
          your cookies.If you turn cookies off, some features will be disabled.
          It won’t affect the user’s experience that make your site experience
          more efficient and may not function properly.
        </p>

        <h3>Third-party disclosure</h3>
        <p>
          We do not sell, trade, or otherwise transfer to outside parties your
          Personally Identifiable Information.
        </p>

        <h3>Third-party links</h3>
        <p>
          Occasionally, at our discretion, we may include or offer third-party
          products or services on our website. These third-party sites have
          separate and independent privacy policies. We therefore have no
          responsibility or liability for the content and activities of these
          linked sites. Nonetheless, we seek to protect the integrity of our
          site and welcome any feedback about these sites.
        </p>

        <h3>Google</h3>
        <p>
          Google’s advertising requirements can be summed up by Google’s
          Advertising Principles. They are put in place to provide a positive
          experience for users.
          https://support.google.com/adwordspolicy/answer/1316548?hl=enWe use
          Google AdSense Advertising on our website.Google, as a third-party
          vendor, uses cookies to serve ads on our site. Google’s use of the
          DART cookie enables it to serve ads to our users based on previous
          visits to our site and other sites on the Internet. Users may opt-out
          of the use of the DART cookie by visiting the Google Ad and Content
          Network privacy policy.
        </p>

        <h3>We have implemented the following:</h3>
        <p>
          <strong>Google Display Network Impression Reporting</strong>
        </p>
        <p>
          We, along with third-party vendors such as Google use first-party
          cookies (such as the Google Analytics cookies) and third-party cookies
          (such as the DoubleClick cookie) or other third-party identifiers
          together to compile data regarding user interactions with ad
          impressions and other ad service functions as they relate to our
          website.
        </p>

        <h3>Opting out:</h3>
        <p>
          <strong>
            Users can set preferences for how Google advertises to you using the
            Google Ad Settings page. Alternatively, you can opt out by visiting
            the Network Advertising Initiative Opt Out page or by using the
            Google Analytics Opt Out Browser add on.
          </strong>
        </p>

        <p>You will be notified of any Privacy Policy changes:</p>
        <p>On our Privacy Policy Page</p>
        <p>You can change your personal information:</p>
        <ul>
          <li>By emailing us</li>
          <li>By calling us</li>
        </ul>

        <h3>How does our site handle Do Not Track signals?</h3>
        <p>
          We honour Do Not Track signals and Do Not Track, plant cookies, or use
          advertising when a Do Not Track (DNT) browser mechanism is in place.
        </p>

        <h3>Does our site allow third-party behavioural tracking?</h3>
        <p>
          It’s also important to note that we allow third-party behavioural
          tracking
        </p>

        <h3>
          Fair Information PracticesIn order to be in line with Fair Information
          Practices we will take the following responsive action, should a data
          breach occur:
        </h3>
        <ul>
          <li>We will notify you via email&nbsp;</li>
          <li>Within 7 business days</li>
        </ul>

        <p>
          We also agree to the Individual Redress Principle which requires that
          individuals have the right to legally pursue enforceable rights
          against data collectors and processors who fail to adhere to the law.
          This principle requires not only that individuals have enforceable
          rights against data users, but also that individuals have recourse to
          courts or government agencies to investigate and/or prosecute
          non-compliance by data{' '}
        </p>

        <h3>We collect your email address in order to:</h3>
        <ul>
          <li>
            Send information, respond to inquiries, and/or other requests or
            questions&nbsp;
          </li>
          <li>
            Send you additional information related to your product and/or
            service
          </li>
          <li>
            Market to our mailing list or continue to send emails to our clients
            after the original transaction has occurred.
          </li>
        </ul>

        <h3>We agree to the following:</h3>
        <ul>
          <li>Not use false or misleading subjects or email addresses</li>
          <li>
            Identify the message as an advertisement in some reasonable
            way.&nbsp;
          </li>
          <li>Include the physical address of our business.</li>
          <li>
            Monitor third-party email marketing services for compliance, if one
            is used.
          </li>
          <li>Honour opt-out/unsubscribe requests quickly.</li>
          <li>
            Allow users to unsubscribe by using the link at the bottom of each
            email.
          </li>
        </ul>

        <h3>
          If at any time you would like to unsubscribe from receiving future
          emails, you can follow the instructions at the bottom of each email
          and we will promptly remove you from ALL correspondence. If you are
          having any problem unsubscribing, please contact us for assistance.{' '}
        </h3>

        <h3>Contacting Us</h3>
        <p>
          If there are any questions regarding this privacy policy, you may
          contact us using the information below:
        </p>
        <p>
          Phone:{' '}
          <a href="tel:35315983936" title="Call Us">
            +353 (0)1 598 3936
          </a>
          <br />
          Email:{' '}
          <a
            href="mailto:killian@dovetail-consultancy.com"
            title="Write us an email"
          >
            marketing@axondivision.com
          </a>
        </p>
        <p>Last Updated: 14 Feb, 2025</p>
      </ArticleSection>
    </PageTemplate>
  );
};

export default PageDataProtectionPolicy;
